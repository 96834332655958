.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: linear-gradient(135deg, rgba(111, 165, 82, 0.1) 0%, rgba(73, 146, 34, 0.2) 100%);
    backdrop-filter: blur(10px);
  }
  
  .login-box {
    background: rgba(255, 255, 255, 0.98);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.05),
      0 10px 15px rgba(111, 165, 82, 0.1);
    width: 100%;
    max-width: 340px;
    position: relative;
    overflow: hidden;
  }
  
  .login-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #6fa552, #499222);
  }
  
  .login-box h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.5px;
  }
  
  .login-form-group {
    margin-bottom: 1rem;
    position: relative;
  }
  
  .login-form-group input {
    width: 100%;
    padding: 0.75rem 1rem;  
    border: 1px solid #e1e8ed;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    background: #f8fafc;
    color: #2c3e50;
}

  .login-form-group::before {
    content: '';
    position: absolute;
    left: 12px;              
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.6;
    z-index: 1; 
  }
  
  .login-form-group input:focus {
    outline: none;
    border-color: #6fa552;
    box-shadow: 0 0 0 3px rgba(111, 165, 82, 0.1);
    background: #ffffff;
  }
  
  .login-error-message {
    color: #e53e3e;
    margin: 0.5rem 0;
    text-align: center;
    font-size: 0.85rem;
    padding: 0.5rem;
    border-radius: 6px;
    background: rgba(229, 62, 62, 0.08);
    border: 1px solid rgba(229, 62, 62, 0.15);
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background: linear-gradient(45deg, #6fa552, #499222);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 0.5rem;
  }
  
  .login-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(111, 165, 82, 0.2);
    background: linear-gradient(45deg, #78b259, #53a528);
  }
  
  .login-button:active {
    transform: translateY(0);
  }
  
  /* Dark theme support */
  @media (prefers-color-scheme: dark) {
    .login-container {
      background: linear-gradient(135deg, rgba(111, 165, 82, 0.05) 0%, rgba(73, 146, 34, 0.1) 100%);
    }
  
    .login-box {
      background: rgba(28, 28, 28, 0.95);
      box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),
        0 10px 15px rgba(111, 165, 82, 0.05);
    }
  
    .login-box h1 {
      color: #e1e8ed;
    }
  
    .login-form-group input {
      background: rgba(248, 250, 252, 0.03);
      border-color: rgba(225, 232, 237, 0.1);
      color: #e1e8ed;
    }
  
    .login-form-group input:focus {
      background: rgba(248, 250, 252, 0.05);
    }
  }