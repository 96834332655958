@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

:root {
  /* Light theme variables */
  --bg-color: #fcfcfc;
  --text-color: #333333;
  --primary-color: #6fa552;
  --secondary-color: #f0f0f0;
  --thidary-color: #fff;
  --hover-bg-color: #f2f2f2;
  --chat-input-color: #f0f0f0;
  --border-color: rgba(74, 122, 206, 0.2);
  --link-color: #499222;
  --shadow-color: rgba(74, 122, 206, 0.08);
  --button-hover-bg-color: #dfdfdf;
  --font-family: 'Inter', sans-serif;
  --font-size: 20px;
  --font-weight: 500;
  --transition-duration: 0.4s;
  --ease-function: cubic-bezier(.36,-0.01,0,.77);
  --transition-speed: 0.4s;
  --transition-timing: var(--ease-function);
  
}

.dark-theme {
  /* Dark theme variables */
  --bg-color: #3a3a3a;
  --text-color: #ccc;
  --primary-color:#499222;
  --secondary-color: #353535;
  --chat-input-color: #454545;
  --hover-bg-color: rgba(255, 255, 255, 0.1);
  --border-color: rgba(255, 255, 255, 0.2);
  --link-color: #cfcfcf;
  --shadow-color: rgba(0, 0, 0, 0.8);
  --button-hover-bg-color: #2a2a2a;
}
html * {
  
  transition: 
    background-color var(--transition-speed) var(--transition-timing),
    color var(--transition-speed) var(--transition-timing),
    border-color var(--transition-speed) var(--transition-timing),
    fill var(--transition-speed) var(--transition-timing),
    box-shadow var(--transition-speed) var(--transition-timing) ;

}

/* Reset any individual transition delays */
* {
  transition-delay: 0s !important;
}
.app-title {
  max-width: 150px;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  padding: 8px 16px;
  background-color: var(--bg-color);
  border-radius: 8px;
  color: var(--link-color);
  letter-spacing: 0.3px;
  transition: all var(--transition-duration) var(--ease-function),
              background-color var(--transition-duration) var(--transition-timing),
              transform var(--transition-duration) var(--ease-function);
  position: relative;
  overflow: hidden;
  z-index: 101;
  transform: translateX(0);
}


.app-title:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px var(--shadow-color);
  background-color: var(--hover-bg-color);
}

.app-container {
  height: 100vh;
  display: flex;
  background-color: var(--bg-color);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.top-bar {
  display: flex;
  align-items: center;
  padding: 6px;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  background-color: transparent;
  color: var(--text-color);
  transition: 
    transform var(--transition-duration) var(--ease-function),
    width var(--transition-duration) var(--ease-function),
    background-color var(--transition-duration) var(--ease-function);
  width: 250px;
  z-index: 110001;
}

.sidebar {
  width: 250px;
  height: 100%;
  background-color: var(--secondary-color);
  backdrop-filter: blur(30px) saturate(2);
  -webkit-backdrop-filter: blur(30px) saturate(2);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  flex: 0 0 250px;
  z-index: 110000;
  white-space: nowrap;
  overflow-x: hidden;
  transform: translateX(0);
  will-change: transform, opacity, flex-basis, background-color;
  transition: 
    transform var(--transition-duration) var(--ease-function),
    opacity var(--transition-duration) var(--ease-function),
    flex-basis var(--transition-duration) var(--ease-function),
    background-color var(--transition-speed) var(--transition-timing);

}

.top-bar.expanded {
  width: 250px;
  
}

.top-bar.collapsed {
  width: 110px;
  background-color: transparent;
  transform: translateX(0);
}

.sidebar.hidden {
  flex-basis: 0;
  transform: translateX(-100%);
  opacity: 0.95;
  transition: 
    transform var(--transition-duration) var(--ease-function),
    opacity var(--transition-duration) var(--ease-function),
    flex-basis var(--transition-duration) var(--ease-function);

}
.sidebar.hidden ~ .chat-container .chat-header .app-title {
  transform: translateX(0px); /* Half of sidebar width (250px/2) */
  transition: transform var(--transition-duration) var(--transition-timing) var(--ease-function);

}


.sidebar-header {
  font-size: 1.4em;
  padding: 20px;
  text-align: center;
  position: relative;
  height: 15%;
}


.sidebar-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 20%;
  transform: translateX(-50%);
  width: 30%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--border-color),
    var(--border-color),
    transparent
  );
}
.sidebar-overlay {
  display: none; /* Hide by default */
}

.toggle-sidebar,
.new-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 100;
  height: 40px;
  transition: all var(--transition-duration) var(--transition-timing);
  background-color: transparent;
}


.top-bar.expanded .toggle-sidebar:hover,
.top-bar.expanded .new-chat:hover {
  background-color: var(--button-hover-bg-color) !important;
  color: #fff;
}


.top-bar.collapsed .toggle-sidebar:hover,
.top-bar.collapsed .new-chat:hover {
  background-color: var(--secondary-color) !important;
  color: #fff;
}


.new-chat {
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0px;
}


.conversation-list {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  margin: 2px 0;
  color: var(--text-color);
  position: relative;
  border-radius: 10px;
  transition: all var(--transition-duration) var(--transition-timing);
}

.conversation-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}

.conversation-item:hover {
  background-color: var(--button-hover-bg-color);
}

.conversation-item.active {
  background-color: var(--button-hover-bg-color);
  border-left: 3px solid var(--primary-color);
  font-weight: 500;
}

.conv-options-btn {
  opacity: 0;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  transition: opacity var(--transition-duration) var(--transition-timing);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.conv-options-btn img {
  width: 20px;
  height: 20px;
  filter: var(--icon-filter);
}

.conversation-item:hover .conv-options-btn {
  opacity: 0.6;
}

.conv-options-btn:hover {
  opacity: 1;
  border-radius: 4px;
}

.conv-dropdown {
  position: absolute;
  right: 0px;
  top: 100%;
  background: var(--secondary-color);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 100;
  min-width: 150px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conv-dropdown button {
  width: 95%;
  margin: 4px 0;
  padding: 8px 12px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color (--transition-duration) var(--transition-timing);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 12px;
  white-space: nowrap;  /* Prevent text wrapping */
  overflow: hidden;     /* Hide overflow text */
  text-overflow: ellipsis; /* Show ... for overflow text */
}

.conv-dropdown button img {
  width: 20px;
  height: 20px;
  filter: var(--icon-filter);
  flex-shrink: 0;      /* Prevent image from shrinking */
}
.conv-dropdown button:hover {
  background-color: var(--button-hover-bg-color);
}

.conversation-name input {
  width: 100%;
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: inherit;
}

.conversation-name input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-left: 250px;
  height: 100vh;
  padding-bottom: 80px; /* Add padding to prevent content from being hidden */
  position: relative;
  transition: margin-left var(--transition-duration) var(--transition-timing);
}


.sidebar.hidden + .chat-container {
  margin-left: min(110px, calc(100% - 800px));
}
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--bg-color);
  position: fixed; /* Change from sticky to fixed */
  top: 0;
  left: 110px;
  right: 0;
  z-index: 10000;
}
.sidebar:not(.hidden)~ .chat-container .chat-header {
  left: 250px;
  /*transiton should be smooth*/
  transition: left var(--transition-duration) var(--transition-timing);
  transition: background-color var(--transition-duration) var(--transition-timing);
}

.sidebar.hidden ~ .chat-container .chat-header {
  left: 110px;
  transition: left var(--transition-duration) var(--transition-timing);
  transition: background-color var(--transition-duration) var(--transition-timing);
}


.header-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  justify-content: flex-end;
}


.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 1.2rem;
  width: 30px; /* Match user-icon width */
  height: 30px; /* Match user-icon height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-toggle:hover {
  background: var(--hover-bg-color);
}

.user-icon {
  width: 32px;
  height: 32px;
  color: var(--text-color);
  transition: all (--transition-duration) var(--transition-timing);
  padding: 4px;
  border-radius: 50%;
}

.user-icon:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

.user-menu {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 200px;
  padding: 8px 0;
  transform-origin: top right;
  animation: dropdownFadeIn 0.2s ease-out;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.dropdown-menu.open {
  display: block;
}

.dropdown-item {
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.2s ease;
  position: relative;
}

.dropdown-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: var(--primary-color);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.dropdown-item:hover {
  background-color: var(--hover-bg-color);
}

.dropdown-item:hover:before {
  opacity: 1;
}

/* Add icons for menu items */
.dropdown-item[onClick="handleLogout"] {
  color: #ff4444;
}

/* Add separator between items */
.dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

/* Dark theme adjustments */
.dark-theme .dropdown-menu {
  background: var(--secondary-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}
.chat-messages-and-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  top: 70px;
  padding: 0 16px;
  min-height: calc(100vh - 70px);
  align-items: center; /* Center content horizontally */
  padding-bottom: 100px;
}
.content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--bg-color);
  position: relative;
}
.sidebar.sidebar:not(.hidden) ~ .chat-container .content-area {
  transform: translateX(0%);
  width: calc(100% ); /* 250px sidebar + 32px padding */
  max-width: 800px;
  margin: 0 auto;
  transition: 
    width var(--transition-duration) var(--transition-timing),
    transform var(--transition-duration) var(--transition-timing),
    background-color var(--transition-duration) var(--transition-timing);
}

.sidebar.sidebar.hidden ~ .chat-container .content-area {
  transform: translateX(0%);
  width: calc(100% - 110px); /* 110px collapsed sidebar + 32px padding */
  max-width: 800px;
  margin: 0 auto;
  transition: 
    width var(--transition-duration) var(--transition-timing),
    transform var(--transition-duration) var(--transition-timing),
    background-color var(--transition-duration) var(--transition-timing);
}

.chat-messages {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 100px;
  background-color: var(--bg-color);
  
}

.message-container {
  display: flex;
  flex-direction: row;
  margin: 4px 0;
}

.message-left {
  margin-right: 8px;
}

.bot-logo {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.dark-theme .bot-logo {
  background-color: white; /* White background for logo */
  padding: 4px; /* Optional padding for visibility */
  border-radius: 50%; /* Optional: round the background */
}


.bot-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  margin: 8px 0;
  margin-left: 0;
  margin-right: auto;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-bottom-left-radius: 8px;
  width: 90%;
  max-width: 720px; /* 90% of parent max-width */
  gap: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.user-message {
  display: flex;
  margin: 6px 0;
  margin-left: auto;
  margin-right: 0;
  background-color: var(--secondary-color);
  color: var(--text-color);
  float: right;
  clear: both;
  max-width: 60%;
  border-radius: 20px 20px 5px 20px;
  padding: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.message-right {
  flex: 1;
}

.message-content {
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 1.5;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  flex: 1;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}


.message-paragraph {
  margin: 8px 0;
}
.message-content strong {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color);
}

.reaction-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  justify-content: flex-start;
}

.reaction-btn {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.dark-theme .reaction-btn img {
  filter: invert(1); /* Invert colors for reaction icons */
}

.reaction-btn img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.reaction-btn:hover {
  transform: scale(1.1);
}

.liked img {
  filter: brightness(1.2);
}

.disliked img {
  filter: brightness(0.8);
}


.chat-input {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0; 
  width: calc(100% - 32px);
  max-width: 800px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding: 8px;
  background-color: var(--chat-input-color);
  border: 1px solid var(--border-color);
  z-index: 10000;
  margin: 0 auto;
  transition: all var(--transition-duration) var(--transition-timing); 
}




.chat-input textarea {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  flex: 1;
  resize: none;
  padding: 10px 16px;
  font-size: 14px;
  border: none;
  background: transparent;
  color: var(--text-color);
  line-height: 1.4;
  height: 40px; /* Fixed initial height */
  min-height: 20px; /* Prevent shrinking below this */
  max-height: 120px;
  overflow-y: auto;
  transition: height (--transition-duration) var(--transition-timing);
  opacity: 1;
}


.chat-input textarea:focus {
  outline: none;
}

.chat-input textarea::placeholder {
  font-family: inherit;
  color: var(--text-color);
}

.chat-input button {
  padding: 10px 15px;
  font-size: 16px;
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  border-left: 1px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  transition: all var(--transition-duration) var(--transition-timing);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-input button:hover {
  background-color: var(--hover-bg-color);
}

.chat-input button i {
  transition: transform (--transition-duration) var(--transition-timing);
}

.chat-input button:hover i {
  transform: translateX(2px);
}

.chat-input textarea:disabled {
  opacity: 1;
  cursor: text; /* Keep text cursor even when disabled */
}

.chat-input button.disabled {
  color: var(--border-color);
  cursor: not-allowed;
}

.chat-input button.active {
  color: var(--primary-color);
}

.chat-input button.disabled:hover {
  background-color: transparent;
}

.chat-input button.disabled i {
  transition: none;
}

.chat-input button.disabled:hover i {
  transform: none;
}

.chat-input button.loading {
  opacity: 0.7;
  cursor: wait;
}

.chat-input button.loading i {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

.chat-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 800px;
  height: 30px;
  background-color: var(--bg-color);
  z-index: 10;
}

.sidebar.sidebar:not(.hidden) ~ .chat-container .chat-footer {
  transform: translateX(0%);
  width: calc(100% ); /* 250px sidebar + 32px padding */
  max-width: 800px;
  margin: 0 auto;
  transition: 
    width var(--transition-duration) var(--transition-timing),
    transform var(--transition-duration) var(--transition-timing),
    background-color var(--transition-duration) var(--transition-timing);
}

.sidebar.sidebar.hidden ~ .chat-container .chat-footer {
  transform: translateX(0%);
  width: calc(100% ); /* 110px collapsed sidebar + 32px padding */
  max-width: 800px;
  margin: 0 auto;
  transition: 
    width var(--transition-duration) var(--transition-timing),
    transform var(--transition-duration) var(--transition-timing),
    background-color var(--transition-duration) var(--transition-timing);
}

.message-paragraph {
  margin: 8px 0;
}

.message-content strong {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color);
  display: inline;
}

.bot-message .message-content {
  max-width: 90%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.message-content span {
  display: inline;
}
.stop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #ff4444;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.stop-button:hover {
  background-color: #ff0000;
}

.stop-button:disabled {
  background-color: #ffaaaa;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  /* Top bar styles */
  .top-bar {
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    box-shadow: none;
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
  }

  /* Toggle and New Chat buttons */
  .toggle-sidebar,
  .new-chat {
    top: 10px;
    width: 44px;
    height: 44px;
    background-color: transparent;
    border-radius: 8px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    z-index: 2001;
    position: relative;
    cursor: pointer;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .toggle-sidebar img,
  .new-chat img {
    width: 24px;
    height: 24px;
  }

  /* App title */
  .app-title {
    position: absolute;
    left: 0;
    margin: 0;
    font-size: 18px;
  }

  /* Sidebar styles */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    height: 100vh;
    background-color: var(--bg-color);
    z-index: 1999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15);
    touch-action: pan-x; /* Enable horizontal touch gestures */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  }

  .sidebar.hidden {
    transform: translateX(-100%);
  }

  .sidebar:not(.hidden) {
    transform: translateX(0);
  }


  /* Overlay when sidebar is open */
  .sidebar:not(.hidden)::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }


  .chat-header {
    position: fixed;
    top: 10px; /* Below top bar */
    left: 0;
    right: 0;
    background-color: var(--bg-color);
    z-index: 1500;
    padding: 10px 0px;
  }

  /* Adjust chat container to account for fixed header */
  .chat-container {
    margin-left: 0 !important;
    margin-top: calc(60px + 56px); /* top bar + chat header height */
    width: 100%;
    height: calc(100vh - 116px); /* Subtract both headers */
    overflow-y: auto;
    z-index: 1;
    padding-bottom: 80px; /* Space for chat input */
  }
  .content-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg-color);
    position: relative;
  }

  .sidebar.sidebar:not(.hidden) ~ .chat-container .content-area {
    transform: translateX(0%);
    width: calc(100% ); /* 250px sidebar + 32px padding */
    max-width: 800px;
    margin: 0 auto;
    transition: 
      width var(--transition-duration) var(--transition-timing),
      transform var(--transition-duration) var(--transition-timing),
      background-color var(--transition-duration) var(--transition-timing);
  }
  .sidebar.sidebar.hidden ~ .chat-container .content-area {
    transform: translateX(0%);
    width: calc(100%); /* 110px collapsed sidebar + 32px padding */
    max-width: 800px;
    margin: 0 auto;
    transition: 
      width var(--transition-duration) var(--transition-timing),
      transform var(--transition-duration) var(--transition-timing),
      background-color var(--transition-duration) var(--transition-timing);
  }	
  .chat-messages-and-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    top: 70px;
    padding: 0 16px;
    min-height: calc(100vh - 70px);
    align-items: center; /* Center content horizontally */
    padding-bottom: 100px;
  }
  /* Keep chat messages below fixed headers */
  .chat-messages {
    padding-top: 10px;
    padding-left: 0%;
  }
  .user-message {
    margin-left: auto; /* Keep it aligned to the right */
    margin-right: 0px;
  }

  /* Ensure chat input stays at bottom */
  .chat-input {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    z-index: 2001;
  }

  .sidebar.sidebar.hidden ~ .chat-container .chat-input {
    left: 50%;
    width: calc(100% );
    transform: translateX(-50%);
  }
  .sidebar.sidebar:not(.hidden) ~ .chat-container .chat-input {
    left: 50%;
    width: calc(100%);
    transform: translateX(-50%);
  }
  .sidebar.sidebar.hidden ~ .chat-container  .chat-messages {
    transform: translateX(0);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 10px;
  }

  .sidebar.sidebar:not(.hidden) ~ .chat-container .chat-messages {
    transform: translateX(0);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0px;
  }

  .sidebar.sidebar:not(.hidden) ~ .chat-container .chat-header {
    left: 0;
    transition: left var(--transition-duration) var(--transition-timing);
    transition: background-color var(--transition-duration) var(--transition-timing);
  }


  .chat-messages{
    padding-left: 0;

  }
  /* Button interactions */
  .toggle-sidebar:active,
  .new-chat:active {
    transform: scale(0.95);
    background-color: var(--hover-bg-color);
  }
}

/* Additional fixes for very small screens */
@media screen and (max-width: 380px) {
  .sidebar {
    width: 90%;
  }

  .toggle-sidebar,
  .new-chat {
    width: 40px;
    height: 40px;
  }

  .app-title {
    font-size: 16px;
    max-width: 120px;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .sidebar {
    height: -webkit-fill-available;
  }
  
  .chat-container {
    height: -webkit-fill-available;
  }
  
  .chat-input {
    bottom: max(20px, env(safe-area-inset-bottom));
  }
}